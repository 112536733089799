<template>
    <div class="">
        <layout>
            <template v-slot:header>
                <div class="white">
                    <v-container class="">
                        <breadcrumbs :items="breadcrumbs" :large="true" class="py-7"></breadcrumbs>
                        <h1 class="heading-1 mb-6">Blog</h1>
                    </v-container>
                </div>
            </template>
            <template v-slot:main>
                <v-container class="d-block">
<v-autocomplete
    v-model="geoModel"
    :items="geoItems"
    :search-input.sync="geoSearch"
    hide-no-data
    hide-selected
    item-text="label"
    item-value="API"
    label="Public APIs"
    placeholder="Start typing to Search"
    prepend-icon="mdi-database-search"
    return-object
></v-autocomplete>
                </v-container>
            </template>
        </layout>
    </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import Layout from '@/components/layouts/BlueTopLayout.vue';
import Breadcrumbs from '@/components/base/Breadcrumbs.vue';

var testLayout = [
	    {"x":0,"y":0,"w":2,"h":2,"i":"0"},
	    {"x":2,"y":0,"w":2,"h":2,"i":"1"},
	    {"x":4,"y":0,"w":2,"h":2,"i":"2"},
	    {"x":0,"y":2,"w":4,"h":1,"i":"3"},
        {"x":4,"y":2,"w":2,"h":2,"i":"2"},
        {"x":0,"y":3,"w":2,"h":2,"i":"2"},
        {"x":2,"y":3,"w":2,"h":2,"i":"2"},
        {"x":4,"y":4,"w":2,"h":2,"i":"2"},
        {"x":0,"y":5,"w":4,"h":1,"i":"3"},

	];

import geodataMixin from '@/mixins/geodataMixin'

export default {
    mixins: [geodataMixin],
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        Layout,
        Breadcrumbs,
    },
    data: () => ({
        layout: testLayout,
        address: '',
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Blog',
                disabled: true,
            }
        ],
    }),
    mounted() {
            // To demonstrate functionality of exposed component functions
            // Here we make focus on the user input
            // this.$refs.address.focus();
        },

        methods: {
            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (addressData, placeResultData, id) {
                this.address = addressData;
            }
        }
}
</script>

<style lang="scss">
.post{
    width: 360px;
    height: 427px;
    float: left;
}
</style>
